import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[6];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  The Velvet Taco is truly a delight with so many one-of-a-kind
                  options on the menu. The margaritas are no slouch either. They
                  roast our own chicken and corn, while making everything from
                  scratch. We usually drop in at 6TH & Congress, but they also
                  have a location at Rock Rose Ave. A spectacular choice is the
                  "Kobe bacon burger" taco which comes with peppered bacon,
                  smoked cheddar, and velvet sauce... with beer-battered
                  cauliflower on the side. Another strong choice is the "Spicy
                  tikka" with chicken crisp tenders and tikka sauce.
                </p>
                <p>
                  This restaurent truly does push the boundaries with various
                  taco concept and are consistenly trying out experimental
                  options to the menu. Oh wait... did we mention the "Chicken &
                  waffle tenders" with peppercorn and maple syrup? ...I guess we
                  just did ;)
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
